import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

import Layout from "../../components/SnickersNFLComponenets/components/layout"

const Prizes = () => (
  <Layout>
    <Helmet>
        <link rel="stylesheet" href="../../SnickersNFL/css/app.css" />
        <link rel="stylesheet" href="../../SnickersNFL/css/responsive.css" />
      </Helmet>
    <div className="d-flex justify-content-center">
      <div className="page-heading text-start">
        <span className="h2 knockoutLiteweightRegular text-uppercase">
          Wanna go to super bowl LVII?
        </span>
        <h1 className="text-uppercase h2">
          Maybe you just need a
          <StaticImage
            className="snickers-logo ms-3"
            src="../../components/SnickersNFLComponenets/images/snickers-3d-logo.png"
            alt="Snickers Logo"
          />
        </h1>
      </div>
    </div>
    <p className="knockoutLiteweightRegular text-lg">
      Now when you grab a SNICKERS, you can enter to win a trip to Super Bowl
      LVII (no purchase necessary), and enjoy all this:
    </p>

    <div className="prizes-hldr">
      <div className="prize">
        <figure>
          <StaticImage
            src="../../components/SnickersNFLComponenets/images/icons/icon-ticket.svg"
            alt="Icon Ticket"
          />
        </figure>
        <p className="text-uppercase h3">Two (2) Tickets to Super Bowl LVII</p>
        <div className="prize-divider d-flex flex-column align-items-center">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="prize">
        <figure className="pt-4">
          <StaticImage
            src="../../components/SnickersNFLComponenets/images/icons/icon-flight.svg"
            alt="Icon Flight"
          />
        </figure>
        <p className="text-uppercase h3">Flights &amp; Lodging</p>
        <div className="prize-divider d-flex flex-column align-items-center">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div className="prize">
        <figure>
          <StaticImage src="../../components/SnickersNFLComponenets/images/icons/icon-card.svg" alt="Icon Card" />
        </figure>
        <p className="text-uppercase h3">
          <span className="d-md-block">
            Plus a $1,000 Visa Gift card to make
          </span>
          your trip the sweetest ever
        </p>
      </div>
    </div>

    <div className="peanut-hldr">
      <figure className="text-center">
        <StaticImage src="../../components/SnickersNFLComponenets/images/peanut.png" alt="Peanut" />
      </figure>
    </div>
  </Layout>
)

export default Prizes
